@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
    margin: 0;

    --primary: #ffffd4;
    --secondary: #40e0d0;
    --white: #fff9ec;
    --black: #333333; 

    
    color: var(--black);
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    margin: 0;
    padding: 0;
    background-color: var(--white);
}

#root {
    color: var(--black);
}