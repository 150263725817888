.contact:hover{
    color: var(--secondary);
    text-decoration: underline;
    transition: all 0.5s ease;
}

input {
    background-color: var(--primary);
    border: 3px solid var(--black);
    border-radius: 3rem;
}

textarea {
    background-color: var(--primary);
    border: 3px solid var(--black);
    border-radius: 3rem;
}