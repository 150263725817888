footer{
    background-color: var(--primary);
}

@keyframes slideUp {
    from {
        transform: translateY(+100%);
    }
    to {
        transform: translateY(0);
    }
}

footer {
    animation: slideUp 0.5s ease;
}
