nav{
    background-color: var(--primary);
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

nav {
    animation: slideIn 0.5s ease;
}

.link-active {
    color: var(--secondary);
    text-decoration: underline;
}

.link:hover {
    color: var(--secondary);
    transition: all 1s ease;
}
  